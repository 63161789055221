import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

import _ from 'lodash'

// Components
import {
  TitleDefault,
  BannerDefault,
  ContentDefault,
  BreadCrumbBar,
} from 'components/Elements'
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import Sidebar from 'components/Sidebar'

function OverOnsPage({ data, pageContext }) {
  const { wordpressPage: page } = data
  return (
    <Layout>
      <SEO yoast={{ meta: page.yoast_meta }} />
      <div className="overons-page">
        <BannerDefault className="banner-detail">
          <div className="row h-100">
            <div className="col-12 image-container p-0">
              <BackgroundImage
                fluid={
                  page.acf.header_image_about.localFile.childImageSharp.fluid
                }
              />
            </div>
            <div className="container">
              <div className="col-12 col-lg-6">
                <h1>{page.title}</h1>
              </div>
            </div>
          </div>
        </BannerDefault>
        <BreadCrumbBar>
          <div className="row m-0">
            <Breadcrumb
              crumbs={pageContext.breadcrumb.crumbs}
              crumbSeparator="-"
              crumbLabel={page.title}
            />
          </div>
        </BreadCrumbBar>
        <section className="about-us">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 pt-130">
                <TitleDefault className="mb-4">{page.title}</TitleDefault>
                <ContentDefault>
                  <div dangerouslySetInnerHTML={{ __html: page.acf.about_us_text }} />
                </ContentDefault>
              </div>
              <div className="col-lg-1" />
              <div className="col-lg-4 p-0 mt-lg-0 mt-5">
                <Sidebar />
              </div>
            </div>
          </div>
          <div className="empty-space-100" />
        </section>
      </div>
    </Layout>
  )
}

export default OverOnsPage

export const aboutQuery = graphql`
  {
    wordpressPage(wordpress_id: { eq: 98 }) {
      id
      title
      acf {
        header_image_about {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        about_us_text
      }
      yoast_meta {
        name
        content
        property
      }
    }
  }
`
